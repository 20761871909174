<template>
  <div>
    <v-form data-vv-scope="form-page" data-testid="form-page">
      <v-card class="mb-2">
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="12"
              v-if="errors.has('form-bundle-registered.general_error_field')"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-alert type="error" text data-testid="page-general-errors">
                    {{ errors.first("form-page.general_error_field") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="activePage.title"
                :error-messages="errors.collect('form-page.title')"
                label="Name"
                placeholder=""
                data-vv-name="name"
                data-vv-validate-on="change"
                data-vv-as="Name"
                outlined
                v-validate="'required'"
                dense
                data-testid="title"
              />
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" md="6" sm="6" xl="12">
                  <TiptapWysiwyg
                    :content.sync="content"
                    data-testid="content"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :to="'/settings/pages'"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import TiptapWysiwyg from "@/components/TiptapWysiwyg"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    TiptapWysiwyg,
  },

  mixins: [GeneralMixin],

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetPageItem").then(() => {
      self.getItemData().then(() => {
        self.$store.dispatch("saveLoaderAction", false)
      })
    })
  },

  beforeDestroy() {
    this.$store.dispatch("resetPageItem")
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new page"
        : `<span class="blue-grey--text text--lighten-4">Edit page</span> ${this.activePage.title}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getPageItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createPage(formData) {
      let self = this
      self.$store
        .dispatch("createPage", formData)
        .then(({ data }) => {
          let params = {
            text: `Page <strong>${this.activePage.title}</strong> was added successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-page")
        })
    },
    editPage(formData) {
      let self = this
      self.$store
        .dispatch("editPage", formData)
        .then(({ data }) => {
          let params = {
            text: `Page <strong>${this.activePage.title}</strong> was edited successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-page")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-page")
      self.$validator.validateAll("form-page").then((result) => {
        if (result) {
          let formData = {
            data: {
              title: self.activePage.title,
              content: self.activePage.content,
            },
          }
          if (this.editedIndex !== -1) {
            formData["slug"] = self.editedIndex
            self.editPage(formData)
          } else {
            self.createPage(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$router
          .push({ path: "/settings/pages" })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activePage: "activePage",
      Pallette: "get_Pallette",
    }),

    content: {
      get() {
        return this.activePage.content
      },
      set(val) {
        this.$store.dispatch("setPageContent", val)
      },
    },
  },
}
</script>

<template>
  <div>
    <!-- Use the component in the right place of the template -->
    <fieldset
      class="d-inline-flex align-start flex-wrap fieldset-settings mx-2"
    >
      <legend class="legend-settings">Your stream settings</legend>
      <div>
        <tiptap-vuetify
          v-model="contentItem"
          :extensions="extensions"
          :toolbar-attributes="{ color: 'grey darken-3' }"
          :card-props="{ flat: true, color: 'grey darken-2' }"
        />
      </div>
    </fieldset>
  </div>
</template>

<script>
// initialize tiptap-vuetify lib
import "@/plugins/tiptap-vuetify"
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
} from "tiptap-vuetify"

export default {
  props: {
    content: {
      type: String,
      default: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,
    },
  },

  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify },

  data: () => ({
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
  }),

  computed: {
    contentItem: {
      get() {
        return this.content
      },
      set(val) {
        this.$emit("update:content", val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
video {
  pointer-events: none;
}

//.btn-rescan-select {
//  background: green;
//}

.fieldset-settings {
  width: 100%;
  //padding: 15px;
  //margin: 10px 0;
  border-color: #424242;
  border-radius: 8px;

  .legend-settings {
    padding: 0 3px;
    margin-left: 7px;
    background-color: transparent;
    color: #424242;
    font-weight: bold;
  }

  & > div {
    width: 100%;
  }
}
</style>
